import React from 'react';
import { renderTechnology} from 'route/Landing/sections/03_OurTech'
import Layout from "../components/Layout"


export default function HowToUseIt({location}){
  return (
    <Layout location={location} t="default">
       <div className="page-how-to fade-in">
        {renderTechnology('default')}
      </div>
    </Layout>
  )
}